@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.slick-dots li.slick-active button,
.ant-carousel .slick-dots li.slick-active button{
  background: #2F2F2F !important;
  background-color: #2F2F2F !important;
}

.slick-dots li{
  background-color: #DBDBDB;
}
.carousel--custom {
  width: 100%;
  margin: auto !important;
}
.carousel--custom > .ant-card-body{
  background: #FFFFFF;
  box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.card--pricing {
  background: #FFFFFF;
  box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
ul li{
  list-style-type: none;
}
.ant-menu-horizontal, .ant-menu-horizontal > .ant-menu-item {
  border-bottom: 0px !important;
  border: 0px !important;
}
/* .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-item-active {
  border-bottom: 0px;
} */
.team--members .ant-typography{
  margin-bottom: 0rem;
}
.team--members .ant-col{
  margin-bottom: 1rem;
}
.firstLetter::first-letter {
  font-size: 200%;
}
.sth {
 display: none;
}
.problem--images, .thefix--images {
  width: 100%;
}
#problems, #fix, #pricing {
  padding-left: 1rem;
  padding-right: 1rem;
}
.contactDeets {
  margin: 0.5rem 0rem;
}
.ant-layout-footer {
  padding: 0px !important;
}
.about--section {
  padding: 0rem 1rem;
}
/* .logo {
  margin-right: 0vh;
} */
/* Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) {
   .sth {
    display: block;
   }
   .carousel--custom {
     width: 500px;
     margin: 1rem auto 5.625rem;
   }
   .ant-layout-footer {
     padding: 24px 50px !important;
   }
   .problem--text {
    text-align: left;
   }
   .about--section {
     padding: 0rem 10.625rem;
   }
 }
 /* Large devices (desktops, 992px and up) */
 @media (min-width: 992px) {
   .sth {
    display: block;
   }
   .carousel--custom {
     width: 500px;
     margin: 1rem auto 5.625rem;
   }
   .ant-layout-footer {
     padding: 24px 50px !important;
   }
   .problem--text, .thefix--text {
    text-align: left;
   }
   .about--section {
     padding: 0rem 10.625rem;
   }
   .logo {
     margin-right: 35% !important;
   }
 }
 /* Extra large devices (large desktops, 1200px and up) */
 @media (min-width: 1200px) {
   .sth {
    display: block;
   }
   .carousel--custom {
     width: 500px !important;
     margin: 1rem auto 5.625rem;
   }
   .ant-layout-footer {
     padding: 24px 50px !important;
   }
   .problem--text {
    text-align: left;
   }
   .about--section {
     padding: 0rem 10.625rem;
   }
   .logo {
     margin-right: 35% !important;
   }
 }
.footerLinks a{
  color: rgb(153, 153, 153);
}
#teamSection .ant-avatar > img {
  height: auto;
}
.ant-carousel .slick-dots li button {
  width: 1.6rem;
  height: 0.4rem;
}

html, body, .App { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
.App {
  text-align: center;
}

